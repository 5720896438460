import React, { Fragment } from 'react';

// @Arrive
import { TableBody } from '@arrive/table';

// @Common Components
import TablePagination from 'commonComponents/Table/TablePagination';
import TableErrorMessage from 'commonComponents/Error/TableErrorMessage';

// @Components
import InvoiceRow from './InvoiceRow/InvoiceRow';
import InvoiceRowContentLoading from './InvoiceRowContentLoading';

// @Types
import { Invoice } from 'types/Shipment.types';
import { InvoiceTableProps } from '../Invoices.types';

const InvoiceTable = ({
  invoicesData,
  invoicesError,
  isLoadingInvoices,
  queryParams,
  handleClearAllFilters,
}: InvoiceTableProps) => {
  const { ReferenceNumber } = queryParams;

  if (isLoadingInvoices) {
    return (
      <TableBody>
        <InvoiceRowContentLoading />
      </TableBody>
    );
  }

  if (!!ReferenceNumber?.length && invoicesData?.total === 0) {
    return (
      <TableErrorMessage
        errorTitle="Sorry, no shipments with that reference number could be found."
        errorSubtitle="Double check that you entered the exact number or try again."
        buttonTitle="Clear Search"
        handleButtonClick={handleClearAllFilters}
        col={8}
        dataTestId="no-shipments-for-ref-number"
      />
    );
  }

  if (invoicesData?.total === 0) {
    return (
      <TableErrorMessage
        dataTestId="no-shipments"
        errorTitle="Sorry, no shipments could be found."
        col={8}
      />
    );
  }

  if (!!invoicesError || invoicesData?.invoices === undefined) {
    return (
      <TableErrorMessage
        col={8}
        errorTitle="Sorry, there was an error retrieving the invoices."
        errorSubtitle="We recommend waiting a few minutes and trying again."
        dataTestId="no-invoices-due-to-error"
      />
    );
  }

  return (
    <>
      <TableBody>
        {invoicesData?.invoices.map((invoice: Invoice, idx) => (
          <Fragment key={`${invoice.LoadNumber}`}>
            <InvoiceRow invoice={invoice} idx={idx} />
          </Fragment>
        ))}
      </TableBody>
      <TablePagination
        dataTestId="invoices-pagination"
        numberOfColumns={8}
        totalRecords={invoicesData?.total as number}
        itemType="Shipments"
      />
    </>
  );
};

export default InvoiceTable;
