import React from 'react';
import { DateTime } from 'luxon';

// @Common Components
import { TableDataCell } from 'commonComponents/Table/TableStyles';
import { TableRow } from '@arrive/table';
import StatusPill from 'commonComponents/Pill/StatusPill';
import TableReferenceNumbers from 'commonComponents/Table/TableReferenceNumbers';
import TableStops from 'commonComponents/Table/TableStops';
import Typography from 'commonComponents/Typography/Typography';

// @Components
import InvoiceBalanceDue from './InvoiceBalanceDue';
import InvoiceDetails from './InvoiceDetails';

// @Helpers
import { InvoiceColors } from 'components/Invoices/InvoicesTable/InvoiceHelpers';

// @Styles
import {
  AmountDataCell,
  BalanceDueDataCell,
  LoadStopsDataCell,
  StatusDataCell,
} from 'components/Invoices/InvoicesTable/InvoiceTableStyles';

// @Theme
import { palette } from 'theme/Theme';

// @Types
import {
  DueDateProps,
  InvoiceRowProps,
} from 'components/Invoices/Invoices.types';

// @Utils
import { formatNumberAmericanStandard } from 'utils/utils';

const DueDate = ({ status, dueDate }: DueDateProps) => {
  if (!dueDate || status === 'Processing' || status === 'Under Review') {
    return (
      <Typography variant="body1" as="p">
        -
      </Typography>
    );
  }

  return (
    <>
      <Typography variant="body1" as="p">
        {DateTime.fromSeconds(dueDate, { zone: 'utc' }).toFormat('MMM dd')}
      </Typography>
      <Typography variant="body2" as="p" color={palette.common.burnout}>
        {DateTime.fromSeconds(dueDate, { zone: 'utc' }).toFormat('yyyy')}
      </Typography>
    </>
  );
};

const InvoiceRow = ({ invoice, idx = 0 }: InvoiceRowProps) => {
  const {
    LoadNumber,
    ReferenceNumbers,
    Stops: LoadStops,
    Mode,
    InvoiceRemainingBalance,
    InvoiceStatus,
    InvoiceTotalAmount,
    InvoiceDueDate,
  } = invoice;

  return (
    <TableRow>
      <TableDataCell data-testid={`invoice-${idx}-reference-number`}>
        <TableReferenceNumbers
          arriveId={LoadNumber}
          referenceNumbers={ReferenceNumbers}
        />
      </TableDataCell>
      <InvoiceDetails invoice={invoice} idx={idx} />
      <TableDataCell data-testid={`invoice-${idx}-mode`}>
        <Typography variant="body1" as="p">
          {Mode}
        </Typography>
      </TableDataCell>
      <LoadStopsDataCell data-testid={`invoice-${idx}-pickup`}>
        <TableStops
          displayTime={false}
          stops={LoadStops['Pickup']}
          type="pickup"
        />
      </LoadStopsDataCell>
      <LoadStopsDataCell data-testid={`invoice-${idx}-delivery`}>
        <TableStops
          displayTime={false}
          stops={LoadStops['Delivery']}
          type="delivery"
        />
      </LoadStopsDataCell>
      <StatusDataCell data-testid={`invoice-${idx}-status`}>
        <StatusPill
          title={InvoiceStatus}
          color={InvoiceColors[InvoiceStatus]}
        />
      </StatusDataCell>
      <TableDataCell data-testid={`invoice-${idx}-due-date`}>
        <DueDate status={InvoiceStatus} dueDate={InvoiceDueDate} />
      </TableDataCell>
      <AmountDataCell data-testid={`invoice-${idx}-total-amount`}>
        <Typography variant="body1" as="p">
          {InvoiceTotalAmount
            ? `$${formatNumberAmericanStandard(InvoiceTotalAmount, true)}`
            : '-'}
        </Typography>
      </AmountDataCell>
      <BalanceDueDataCell data-testid={`invoice-${idx}-balance-due`}>
        <InvoiceBalanceDue
          status={InvoiceStatus}
          balanceDue={InvoiceRemainingBalance}
          totalAmount={InvoiceTotalAmount}
        />
      </BalanceDueDataCell>
    </TableRow>
  );
};

export default InvoiceRow;
