import React from 'react';

// @Arrive
import Pagination from '@arrive/pagination';
import { TableRow } from '@arrive/table';

// @Hooks
import { useQueryParams, useSetQueryParams } from 'hooks/useReactRouterQueries';

// @Types
import { PaginationOptions } from 'types/pagination';

// @Styles
import {
  PageSizeContainer,
  PaginationContainer,
  PaginationArrowLeft,
  PaginationArrowRight,
  TableFooter,
  PageNumberContainer,
  InvisibleItem,
} from './TableStyles';

const PAGE_SIZE_OPTIONS = [5, 25, 50, 75];

interface TablePaginationProps {
  numberOfColumns: number;
  totalRecords: number;
  itemType: string;
  paginationOptions?: PaginationOptions;
  dataTestId?: string;
}

const TablePagination = ({
  numberOfColumns,
  totalRecords,
  paginationOptions = { pageNumber: 1, pageSize: 25 },
  itemType,
}: TablePaginationProps) => {
  // get and set query params
  const {
    pageNumber: pageNumberQs = paginationOptions.pageNumber,
    pageSize: pageSizeQs = paginationOptions.pageSize,
    ...queryParams
  } = useQueryParams();
  const setQueryParams = useSetQueryParams();

  const pageNumber = parseInt(pageNumberQs as string);
  const pageSize = parseInt(pageSizeQs as string);

  const isFirstPage = pageNumber === 1;
  const isLastPage = pageNumber >= totalRecords / pageSize;

  return (
    <TableFooter>
      <TableRow>
        <td colSpan={numberOfColumns}>
          <PaginationContainer>
            <InvisibleItem />
            <PageNumberContainer>
              <PaginationArrowLeft
                data-testid="previous-page-arrow"
                disabled={isFirstPage}
                onClick={() => {
                  /* istanbul ignore next 
                can't test these items the way Legos have written them
                */
                  !isFirstPage
                    ? setQueryParams({
                        ...queryParams,
                        pageSize,
                        pageNumber: pageNumber - 1,
                      })
                    : null;
                }}
              />
              <Pagination
                currentPage={pageNumber}
                itemTypeText={itemType}
                pageSize={pageSize}
                totalCount={totalRecords}
                onPageChange={(page: number) => {
                  setQueryParams({
                    ...queryParams,
                    pageSize: pageSize,
                    pageNumber: page,
                  });
                }}
              />
              <PaginationArrowRight
                data-testid="next-page-arrow"
                disabled={isLastPage}
                onClick={() => {
                  /* istanbul ignore next 
                can't test these items the way Legos have written them
                */
                  !isLastPage
                    ? setQueryParams({
                        ...queryParams,
                        pageSize,
                        pageNumber: pageNumber + 1,
                      })
                    : null;
                }}
              />
            </PageNumberContainer>
            <PageSizeContainer>
              <span>Page Size:</span>
              <select
                data-testid="page-size-dropdown"
                value={pageSize}
                onChange={(e) =>
                  setQueryParams({
                    ...queryParams,
                    pageSize: e.target.value,
                    pageNumber: 1,
                  })
                }
              >
                {PAGE_SIZE_OPTIONS.map((option) => (
                  <option
                    value={option}
                    key={option}
                    data-testid={`page-size-${option}`}
                  >
                    {option}
                  </option>
                ))}
              </select>
            </PageSizeContainer>
          </PaginationContainer>
        </td>
      </TableRow>
    </TableFooter>
  );
};

export default TablePagination;
