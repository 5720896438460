import React, { useMemo, useState } from 'react';

//@Components
import InvoiceTable from './InvoicesTable/InvoiceTable';
import InvoiceFilters from './InvoicesFilters/InvoiceFilters';
import InvoiceTableHeader from './InvoicesTable/InvoiceTableHeader';

//@Common Components
import { StyledTable } from 'commonComponents/Table/TableStyles';
import Typography from 'commonComponents/Typography/Typography';

//@Hooks
import { useQueryParams, useSetQueryParams } from 'hooks/useReactRouterQueries';
import { useGetInvoices } from './useGetInvoices';

//@Styles
import { PageContainer, HeaderContainer } from 'styles/PageStyles';

const Invoices = () => {
  const queryParams = useQueryParams();
  const setQueryParams = useSetQueryParams();
  const { ReferenceNumber: QueryParamsRefNumber } = queryParams;
  const [referenceNumber, setReferenceNumber] = useState(
    (QueryParamsRefNumber as string) || '',
  );

  const handleSearch = (label: string, value: string) => {
    setQueryParams({
      ...queryParams,
      [label]: value,
    });
  };

  const memoizedHandleSearch = useMemo(
    () => handleSearch,
    [QueryParamsRefNumber],
  );

  const {
    data: invoicesData,
    isError: invoicesError,
    isInitialLoading: isLoadingInvoices,
  } = useGetInvoices();

  const handleClearAllFilters = () => {
    setQueryParams({
      ...queryParams,
      ReferenceNumber: null,
    });
    setReferenceNumber('');
  };

  return (
    <PageContainer>
      <HeaderContainer data-testid="invoices-header">
        <Typography variant="h1">Invoices</Typography>
      </HeaderContainer>
      <InvoiceFilters
        handleClearAllFilters={handleClearAllFilters}
        referenceNumber={referenceNumber}
        setReferenceNumber={setReferenceNumber}
        totalShipments={invoicesData?.total || 0}
        handleSearch={memoizedHandleSearch}
      />
      <StyledTable data-testid="invoices-table">
        <InvoiceTableHeader />
        <InvoiceTable
          invoicesData={invoicesData}
          invoicesError={invoicesError}
          isLoadingInvoices={isLoadingInvoices}
          queryParams={queryParams}
          handleClearAllFilters={handleClearAllFilters}
        />
      </StyledTable>
    </PageContainer>
  );
};

export default Invoices;
