import React from 'react';
import { TableHeaderRow } from '@arrive/table';

// @Common Components
import Typography from 'commonComponents/Typography/Typography';

// @Styles
import { InvoiceHeader, DueHeader, AmountHeader } from './InvoiceTableStyles';

const LeftAlignedInvoiceTableHeaders = [
  'Reference',
  'Invoice',
  'Mode',
  'Pickup',
  'Delivery',
  'Status',
  'Due Date',
];

const TableHeader = () => {
  return (
    <TableHeaderRow>
      {LeftAlignedInvoiceTableHeaders.map((header) => (
        <InvoiceHeader key={header}>
          <Typography variant="overline">{header}</Typography>
        </InvoiceHeader>
      ))}
      <AmountHeader>
        <Typography variant="overline">Total Amount</Typography>
      </AmountHeader>
      <DueHeader>
        <Typography variant="overline">Due</Typography>
      </DueHeader>
    </TableHeaderRow>
  );
};

export default TableHeader;
