import styled from '@emotion/styled';

// @Common Components
import { Table as ArriveTable, TableRow } from '@arrive/table';
import Typography from 'commonComponents/Typography/Typography';

// @Icons
import ArrowLeft from '@arrive/ui-icons/svg/ic_left_arrow.svg';
import ArrowRight from '@arrive/ui-icons/svg/ic_right_arrow.svg';

// @Theme
import { palette } from 'theme/Theme';
import { fontSizes, spacing } from 'theme/Units';

export const StyledTable = styled(ArriveTable)`
  width: 100%;
  overflow-x: scroll;
  border-top: ${`solid 1px ${palette.common.chrome}`};
  margin-bottom: ${spacing.large};

  thead {
    text-align: left;
  }

  th {
    box-shadow: none;
  }

  td {
    height: 103px;
  }

  tr {
    :hover {
      background-color: ${palette.common.tandem};
    }
  }
`;

export const TableFooter = styled.tfoot`
  .arrive_Pagination__container {
    width: fit-content;
  }

  .arrive_Pagination__incrementButton {
    display: none;
  }

  svg {
    position: relative;
    top: 7px;
    fill: ${palette.common.smokey};

    :hover {
      fill: ${palette.common.axle};
    }
  }
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const InvisibleItem = styled.div`
  visibility: hidden;
  width: 150px;
`;

export const PageNumberContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
`;

export const PageSizeContainer = styled.div`
  font-size: ${fontSizes.small};
  padding: ${`${spacing.none} ${spacing.normal}`};
  width: 150px;
  select {
    padding: ${spacing.xxsmall};
    border: none;
    border-bottom: 1px solid ${palette.common.chrome};
  }
`;

export const PaginationArrowLeft = styled(ArrowLeft)<{ disabled: boolean }>`
  fill: ${({ disabled }) => disabled && palette.common.LFGray} !important;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

export const PaginationArrowRight = styled(ArrowRight)<{ disabled: boolean }>`
  fill: ${({ disabled }) => disabled && palette.common.LFGray} !important;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;

export const TableDataCell = styled.td`
  gap: ${spacing.xxsmall};
  padding: ${spacing.small} 0 ${spacing.small} ${spacing.normal};
  vertical-align: top;
`;

export const ReferenceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: ${spacing.small};
`;

export const ReferenceValue = styled(Typography)`
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TableRowLoadingColumn = styled.div`
  display: flex;
  flex-direction: column;
  > * {
    margin-top: 2px;
  }
`;

export const TableRowLoading = styled(TableRow)`
  > td {
    padding-top: ${spacing.gutter};
  }
`;
