import TextEntry from '@arrive/text-entry';
import styled from '@emotion/styled';

// @Theme
import { palette } from 'theme/Theme';
import { spacing } from 'theme/Units';

export const FilterOptions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
`;

export const FiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: ${spacing.normal} 0;

  > div {
    display: flex;
    gap: ${spacing.xsmall};
  }
`;

export const FilterResults = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 16px;
`;

export const ReferenceSearch = styled(TextEntry)`
  width: 320px;
  height: 54px;
  margin-right: 8px;

  & svg {
    padding-top: 3px;
  }
`;

export const ClearFiltersButton = styled.button`
  background: none;
  border: none;
  font-size: 14px;
  cursor: pointer;
  color: ${palette.common.arrive};
  margin-left: 8px;
`;
